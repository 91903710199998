import { z } from "zod";
import { InteractiveTable } from "../../../_sharedComponents/InteractiveDataTable";

export namespace SubscriptionsTableSorts {
  export const types = [
    "shopifyId",
    "upcomingOrderDate",
    "emailOrName",
    "email",
    "estimatedTotal",
    "status",
    "createdDate",
    "sellingPlanId",
  ] as const;
  export const schema = z.enum(types);
  export type Sort = z.infer<typeof schema>;

  interface SortInformation {
    name: Sort;
    displayName: string;
  }

  export const SortInfo: Record<Sort, SortInformation> = {
    shopifyId: {
      name: "shopifyId",
      displayName: "Subscription ID",
    },
    upcomingOrderDate: {
      name: "upcomingOrderDate",
      displayName: "Upcoming order",
    },
    emailOrName: {
      name: "emailOrName",
      displayName: "Customer",
    },
    email: {
      name: "email",
      displayName: "Customer email",
    },
    estimatedTotal: {
      name: "estimatedTotal",
      displayName: "Est. total",
    },
    status: {
      name: "status",
      displayName: "Status",
    },
    createdDate: {
      name: "createdDate",
      displayName: "Created date",
    },
    sellingPlanId: {
      name: "sellingPlanId",
      displayName: "Subscription program",
    },
  };

  export type SelectedSort = InteractiveTable.SelectedSort & {
    sortType: Sort;
  };
}
