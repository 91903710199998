import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import { InteractiveTable } from "@vendor-app/app/_sharedComponents/InteractiveDataTable";
import { SubscriptionsTableFilters } from "./filters";
import { SubscriptionsTableSorts } from "./sorts";

export const generateQueryFromStore = (
  selectedFilters: InteractiveTable.SelectedFilter[],
  selectedSort: InteractiveTable.SelectedSort,
  pagination: InteractiveTable.Pagination
): IDeserializedPaginatedQuery => {
  const query: IDeserializedPaginatedQuery = {
    pageNumber: pagination.pageNumber - 1,
    /**
     * Set by filters
     */
    pageSize: 0,
  };

  for (const filter of selectedFilters) {
    const chosenFilter = SubscriptionsTableFilters.SubscriptionsTableFilters[filter.filterType];
    if (chosenFilter) {
      chosenFilter.addToQuery(query, filter.value);
    }
  }

  {
    const chosenSort = SubscriptionsTableSorts.SortInfo[selectedSort.sortType];
    if (chosenSort) {
      query.orderBy = {
        [chosenSort.name]: selectedSort.value,
      };
    }
  }

  return query;
};
