import { z } from "zod";
import { InteractiveTable } from "../../../_sharedComponents/InteractiveDataTable";

export namespace SubscriptionsTableColumns {
  export const types = [
    "id",
    "shopifyId",
    "upcomingOrderDate",
    "emailOrName",
    "email",
    "estimatedTotal",
    "items",
    "status",
    "createdDate",
    "sellingPlanId",
  ] as const;
  export const typeSchema = z.enum(types);
  export type Column = z.infer<typeof typeSchema>;

  interface ColumnInformation {
    name: Column;
    title: string;
  }

  export const SubscriptionsTableColumnInfo: Record<Column, ColumnInformation> = {
    id: {
      name: "id",
      title: "ID",
    },
    shopifyId: {
      name: "shopifyId",
      title: "Subscription ID",
    },
    upcomingOrderDate: {
      name: "upcomingOrderDate",
      title: "Upcoming order",
    },
    emailOrName: {
      name: "emailOrName",
      title: "Customer",
    },
    email: {
      name: "email",
      title: "Customer email",
    },
    estimatedTotal: {
      name: "estimatedTotal",
      title: "Est. total",
    },
    items: {
      name: "items",
      title: "Items",
    },
    status: {
      name: "status",
      title: "Status",
    },
    createdDate: {
      name: "createdDate",
      title: "Created date",
    },
    sellingPlanId: {
      name: "sellingPlanId",
      title: "Subscription program",
    },
  };

  export type SelectedColumn = InteractiveTable.SelectedColumn & {
    columnType: Column;
  };

  export const shownTableColumns: Column[] = [
    "shopifyId",
    "upcomingOrderDate",
    "emailOrName",
    "email",
    "estimatedTotal",
    "items",
    "status",
    "createdDate",
    "sellingPlanId",
  ];
}
