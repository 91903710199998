import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { InteractiveTable, createInteractiveTable } from "../..";
import { useCallback, useMemo, useState } from "react";
import { Button, Card, ChoiceList, Popover } from "@shopify/polaris";
import { ColumnWithTextMajor } from "@shopify/polaris-icons";

interface GenericTableColumnProps<
  DataType extends InteractiveTable.DataType,
  FilterType extends InteractiveTable.SelectedFilter,
  ColumnType extends InteractiveTable.SelectedColumn,
  SortType extends InteractiveTable.SelectedSort,
  PersistType extends {},
> {
  storeAccess: ReturnType<typeof createInteractiveTable<DataType, FilterType, ColumnType, SortType, PersistType>>;
  shownColumns: ColumnType["columnType"][];
  columnInfo: Record<ColumnType["columnType"], { title: string }>;
  validateSelectedColumns: (data: string[]) => ColumnType["columnType"][];
}

export const GenericInteractiveTableColumn = <
  DataType extends InteractiveTable.DataType,
  FilterType extends InteractiveTable.SelectedFilter,
  ColumnType extends InteractiveTable.SelectedColumn,
  SortType extends InteractiveTable.SelectedSort,
  PersistType extends {},
>({
  storeAccess,
  shownColumns,
  columnInfo,
  validateSelectedColumns,
}: GenericTableColumnProps<DataType, FilterType, ColumnType, SortType, PersistType>) => {
  const enabledColumns = storeAccess.useEnabledColumns();
  const actions = storeAccess.useActions();
  const { addToast } = useToast();

  const [openColumnPopover, setOpenColumnPopover] = useState(false);

  const columnNames = useMemo(() => {
    return shownColumns.map(column => {
      return {
        label: columnInfo[column].title,
        value: column,
      };
    });
  }, [shownColumns]);

  const onParse = useCallback(
    (data: string[]): void => {
      const parsedValues = validateSelectedColumns(data);
      if (parsedValues.length > 0) {
        actions.column.update(parsedValues);
      } else {
        addToast("Please select at least one column");
      }
    },
    [addToast, actions, validateSelectedColumns]
  );

  return (
    <Popover
      fluidContent
      preventCloseOnChildOverlayClick
      active={openColumnPopover}
      activator={
        <Button icon={ColumnWithTextMajor} onClick={() => setOpenColumnPopover(!openColumnPopover)}>
          Columns
        </Button>
      }
      onClose={() => setOpenColumnPopover(false)}
    >
      <Card>
        <ChoiceList
          choices={columnNames}
          selected={enabledColumns}
          title="Columns"
          titleHidden
          allowMultiple
          onChange={onParse}
        ></ChoiceList>
      </Card>
    </Popover>
  );
};
