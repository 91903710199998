import { AutocompleteProps, TextFieldProps } from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { ScheduledDeliveryGroupWithPurchSt } from "@smartrr/shared/entities/PurchaseState/scheduleUtils";
import { RRuleManager } from "@smartrr/shared/entities/Schedule/RRuleManager";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import {
  IShopifyLiquidSellingPlanGroup,
  IShopifyLiquidSellingPlanWithGroupIdAndName,
} from "@smartrr/shared/shopifyLiquid/selling_plan_group";
import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";

import {
  TypedTableColumnType,
  TypedTableColumnValue,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";

export interface AdminSubscriptionDetailsProps {
  customerId: string;
  subscriptionId: string;
}

export type SellingPlanGroup = Record<string, IShopifyLiquidSellingPlanGroup[]>;

export type SubscriptionTableColumnKeyType =
  | "shopifyId"
  | "upcomingOrderDate"
  | "emailOrName"
  | "email"
  | "estimatedTotal"
  | "items"
  | "status"
  | "createdDate"
  | "sellingPlanId";

export type SubscriptionCancellationTableColumnKeyType =
  | "shopifyId"
  | "createdDate"
  | "emailOrName"
  | "email"
  | "estimatedTotal"
  | "items"
  | "status"
  | "cancellationReason";

export type SubscriptionFailureTableColumnKeyType =
  | "shopifyId"
  | "createdDate"
  | "emailOrName"
  | "email"
  | "estimatedTotal"
  | "status"
  | "retryCount"
  | "failureReason"
  | "nextRetry"
  | "items";

export type HomePageSubscriptionColumns =
  | TypedTableColumnType<SubscriptionTableColumnKeyType>
  | TypedTableColumnType<SubscriptionFailureTableColumnKeyType>
  | TypedTableColumnType<SubscriptionCancellationTableColumnKeyType>;

export type HomePageSubscriptionColumnTypes =
  | SubscriptionTableColumnKeyType
  | SubscriptionFailureTableColumnKeyType
  | SubscriptionCancellationTableColumnKeyType;

export interface IFailedBillingsInfo {
  custRel: {
    firstName: string | undefined;
    lastName: string | undefined;
    shopifyId: string | undefined;
  };
  shopifyId: string | undefined;
  error: string | undefined | null;
}

export enum SelectionType {
  All = "all",
  Page = "page",
  Multi = "multi",
  Single = "single",
}

export enum OrdersAndEventDataTabsEnum {
  UPCOMING_ORDERS,
  ORDER_HISTORY,
  TRANSACTION_HISTORY,
}

export enum SubscriptionDetailsTabsEnum {
  SUBSCRIPTION_DETAILS,
  ORDERS_AND_EVENT_HISTORY,
}

export interface CustomerAutocompleteProps {
  selected: string[];
  handleCustomerChange: (emails: string[]) => void;
  textFieldProps?: Partial<TextFieldProps>;
}
export interface SubscriptionShopifyIdAutocompleteProps {
  selected: string[];
  handleSubscriptionIdChange: (ids: string[]) => void;
  textFieldProps?: Partial<TextFieldProps>;
}

export interface ItemsAutocompleteProps extends Omit<AutocompleteProps, "textField" | "selected" | "onSelect"> {
  selected: string[];
  textFieldProps?: Partial<TextFieldProps>;
  onSelect?(value: string[]): void;
  onChange?(value: string): void;
  options: { value: string; label: string }[];
}

export interface IScheduledEventWithProjectedTotal
  extends ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> {
  totalForScheduleIdx: string;
}

export interface ILineItemToUpdateBase {
  quantity: number | undefined;
  variantId?: string;
  sellingPlanId: string | number;
  skdIdx?: number | undefined;
}

export interface ILineItemToAdd extends ILineItemToUpdateBase {
  price: number;
}

export interface ILineItemToUpdate extends ILineItemToUpdateBase {
  id: string;
  price?: number;
}

export interface ILineItemToSwap extends ILineItemToUpdate {
  variantIdForReplacement: string;
}

export interface IAddLineFormValidationSchema {
  modalSelectedVariantId?: boolean | undefined;
  price?: boolean | undefined;
  quantity?: boolean | undefined;
}

export type CPSLineItemsTableColumnKeyType = "itemName" | "quantity" | "price";

export type CPSLineItemsTableColumnType = {
  [key in CPSLineItemsTableColumnKeyType]: TypedTableColumnValue;
};

export interface CPSLineItemsParsedDataType {
  itemName: JSX.Element;
  quantity: JSX.Element;
  price: JSX.Element;
  lineItem: SubscriptionDetailsLineItem;
}

export interface ISellingPlanSelect {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship;
  sellingPlanOptions: IShopifyLiquidSellingPlanWithGroupIdAndName[];
  rruleManager: RRuleManager | undefined;
  sellingPlanGroup: ISmartrrSellingPlanGroup | undefined;
  areActionsDisabled: boolean;
  nextDelivery: ScheduledDeliveryGroupWithPurchSt<IPurchaseStateWithCustomerRelationship> | undefined;
}

export interface IPurchasableOption extends OptionDescriptor {
  title: string;
}
