import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { createInteractiveTable } from "../../../_sharedComponents/InteractiveDataTable";
import { SubscriptionsTableFilters } from "./filters";
import { SubscriptionsTableSorts } from "./sorts";
import { getCustomerPurchaseStates } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { generateQueryFromStore } from "./query";
import { SubscriptionsTableColumns } from "./columns";

const initialData: IPurchaseStateWithCustomerRelationship[] = [];

interface SubscriptionTablePersistType {
  selectedColumns: SubscriptionsTableColumns.SelectedColumn[];
  selectedSort: SubscriptionsTableSorts.SelectedSort;
}

const CURRENT_STORAGE_VERSION = 0;

export const SubscriptionsTableAccess = createInteractiveTable<
  IPurchaseStateWithCustomerRelationship,
  SubscriptionsTableFilters.SelectedFilter,
  SubscriptionsTableColumns.SelectedColumn,
  SubscriptionsTableSorts.SelectedSort,
  SubscriptionTablePersistType
>({
  initialData,
  initialFilters: [...SubscriptionsTableFilters.types],
  initialSorts: [...SubscriptionsTableSorts.types],
  initialPagination: {
    pageNumber: 1,
    maxPages: 1,
  },
  initialSelectedFilters: [
    {
      filterType: "pageSize",
      value: ["30"],
    },
  ],
  initialSelectedSort: {
    sortType: "upcomingOrderDate",
    value: "ASC",
  },
  initialSelectedColumns: SubscriptionsTableColumns.shownTableColumns.map(c => ({
    columnType: c,
    value: true,
  })),
  async onUpdateTable(currentData, selectedFilters, selectedSort, pagination) {
    const query = generateQueryFromStore(selectedFilters, selectedSort, pagination);

    const subscriptions = await getCustomerPurchaseStates({ queryParams: query });

    if (subscriptions.type === "success") {
      return {
        data: subscriptions.body.data,
        maxPages: subscriptions.body.totalPages,
      };
    }

    return {
      data: [],
      maxPages: 0,
    };
  },
  persistSettings: {
    name: "smartrr-subscription-table-settings",
    version: CURRENT_STORAGE_VERSION,
    partialize: store => ({
      selectedColumns: store.selectedColumns,
      selectedSort: store.selectedSort,
    }),
    migrate(storedState, version) {
      if (version === CURRENT_STORAGE_VERSION) {
        return storedState as SubscriptionTablePersistType;
      }
    },
  },
});
