import { z } from "zod";
import { InteractiveTable } from "../../../_sharedComponents/InteractiveDataTable";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";

const hasSingleValue = (value: string[]) => {
  return value.length === 1 && value[0] !== "";
};

export namespace SubscriptionsTableFilters {
  export const types = [
    "pageSize",
    "upcomingOrderDate",
    "createdDate",
    "emailOrNameOrId",
    "status",
    "estimatedTotal",
    "items",
    "sellingPlanId",
  ] as const;
  const schema = z.enum(types);
  export type Filter = z.infer<typeof schema>;

  interface FilterInformation {
    name: Filter;
    title: string;
    addToQuery: (query: IDeserializedPaginatedQuery, value: string[]) => void;
  }

  export const SubscriptionsTableFilters: Record<Filter, FilterInformation> = {
    pageSize: {
      name: "pageSize",
      title: "Page size",
      addToQuery(query, value) {
        query.pageSize = Number(value[0] ?? "30");
      },
    },
    upcomingOrderDate: {
      name: "upcomingOrderDate",
      title: "Upcoming order",
      addToQuery(query, value) {
        const startDate = value[0];
        const endDate = value[1];
        query.filterIn = Object.assign(query.filterIn ?? {}, {
          upcomingOrderDate: [startDate, endDate],
        });
      },
    },
    createdDate: {
      name: "createdDate",
      title: "Created date",
      addToQuery(query, value) {
        const startDate = value[0];
        const endDate = value[1];
        query.filterIn = Object.assign(query.filterIn ?? {}, {
          createdDate: [startDate, endDate],
        });
      },
    },
    emailOrNameOrId: {
      name: "emailOrNameOrId",
      title: "Search Text",
      addToQuery(query, value) {
        if (hasSingleValue(value)) {
          query.filterIn = Object.assign(query.filterIn ?? {}, {
            emailOrNameOrId: value[0].split(" ").filter(v => v !== ""),
          });
        }
      },
    },
    status: {
      name: "status",
      title: "Status",
      addToQuery(query, value) {
        query.filterIn = Object.assign(query.filterIn ?? {}, {
          status: value,
        });
      },
    },
    estimatedTotal: {
      name: "estimatedTotal",
      title: "Est. total",
      addToQuery(query, values) {
        query.filterLike = Object.assign(query.filterLike ?? {}, {
          estimatedTotal: values,
        });
      },
    },
    items: {
      name: "items",
      title: "Items",
      addToQuery(query, value) {
        query.filterIn = Object.assign(query.filterIn ?? {}, {
          variantId: value,
        });
      },
    },
    sellingPlanId: {
      name: "sellingPlanId",
      title: "Subscription program",
      addToQuery(query, value) {
        query.filterIn = Object.assign(query.filterIn ?? {}, {
          sellingPlanId: value.filter(v => v.length > 0),
        });
      },
    },
  };

  export type SelectedFilter = InteractiveTable.SelectedFilter & {
    filterType: Filter;
  };
}
